@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-container{
    display: flex;
    flex: 1;
    background-color: #f3f3f3;
    justify-content: center;
    align-items: center;
}


main .spinner-container {
    display: block;
    min-height: 60vh;
    background-color: #fafbfe;
    text-align: center;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

main .spinner {
    margin: 25vh auto;
}