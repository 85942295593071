.main{
    display: flex;
    flex: 1;
    background-color: #f3f3f3;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.error-container{
    text-align: center;
    color: grey;
}