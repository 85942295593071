@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "OpenSans";
    src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

*{
    margin: 0;
    padding: 0;
}

html,
body {
  height: 100%;
}

#root{
    font-family: "OpenSans";
    font-size: .875rem;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    background-color: #fafbfe;
    display: flex;
    flex-direction: column;
    height: 100%;
}

header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 500;
    display: flex;
    flex-direction: column;
    background-color: #fafbfe;
    border-bottom: 1.5px solid #000;
}

main {
    margin-top: 125px;
    flex: 1;
    text-align: center;
}

.etapas{
    margin: .5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.etapas h3{
    margin: 0 1rem;
    font-size: medium;
}

.etapas img {
    width: 35px;
}

.etapas span {
    display: inline-flex;
}

.etapas .timer {
    margin-left: 1rem;
    color: red;
    font-size: large;
}

.disable{
    opacity: .2;
}

.line-header {
    width: 100%;
    height: 15px;
}

.bg-destaque {
    background: var(--primary) !important;
}

.icon{
    margin-top: -.2rem;
    display: none;
}

header .container-header {
    display: flex;
    padding: .8rem;
    flex-direction: row;
}

.img-logo img {
    max-height: 6.5rem;
}

.infos {
    font-size: small;
    width: 100%;
    margin-left: 1rem;
    font-weight: bolder;
}

.infos h3 {
    text-transform: uppercase;
}

.infos span {
    color: inherit;
    font-weight: normal;
}

.cap {
    text-transform: uppercase;
}

.info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: .5rem;
}

.info-row p {
    margin-right: .5em;
}

.info-row span {
    font-weight: initial;
}

a {
    color: #727cf5;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.texto-destaque {
    color: var(--primary) !important;
    font-weight: bolder;
}

.footer-checkout {
    background: #E8E8E8;
    width: 100%;
    font-size: 0.875rem;
    margin: 0;
    font-family: 'OpenSans';
    text-align: center;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}

.mt-1 {
    margin-top: .375rem!important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.text-center {
    text-align: center!important;
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}

.footer-checkout p {
    font-weight: bolder;
    font-size: smaller;
}

.font-weight-bold {
    font-weight: 700!important;
}

h1{
    font-weight: bolder;
    font-size: x-large;
    margin: 1rem 0;
}

.veiculo-img{
    width: 80%;
    border: .5rem solid transparent;
    background-color: transparent;
    border-radius: 25px;
    border-image: url('../assets/img/img-back.jpeg') 13 fill;
}

.no-border {
    border: none
}

.info{
    text-align: start;
}

video{
    max-width: 300px;
    max-height: 300px;
}

h4{
    background-color: var(--primary);
    color: white;
    width: fit-content;
    padding: .5rem 2rem;
    text-transform: uppercase;
    font-weight: 400;
}

.info ul{
    margin: .5rem 0;
    list-style: initial;
    padding-left: 2.5rem;
    font-weight: bolder;
    font-size: small;
    color: #3f4447;
}

main span{
    font-weight: bolder;
}

main p {
    margin: auto 1rem;
}

h2{
    text-transform: uppercase;
}

button{
    background-color: var(--primary);
    border: none;
    color: white;
    padding: 15px 32px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    display: block;
    margin: 1.5rem auto;
}

.ma {
    margin: 1rem 0;
}

.camera{
    width: fit-content;
    margin: .5rem auto;
}

.camera label{
    display: flex;
    flex: 1;
}

.regras {
    text-align: start;
}

.regras h2 {
    text-align: center;
    font-weight: 800;
    line-height: 1.5rem;
    color: rgb(58, 58, 58);
    font-size: large;
    margin: 1rem 0;
}

.regras div {
    margin: .5rem;
    display: flex;
    flex-direction: row;
}

.icone {
    padding-right: .5rem;
    height: 1.5rem;
    align-self: center;
}

.regras span {
    font-weight: normal;
}

.regras button {
    background-color: #484848;
    text-transform: uppercase;
}
.regras button:active {
    background-color: #333333;
}

.location img {
    margin: 1rem auto 0 auto;
    width: 50vw;
    display: block;
}

.location small {
    display: block;
    margin: 0 3rem 1rem 3rem;
    line-height: 1rem;
}

.location ul {
    list-style: decimal;
    padding-inline-start: 30px;
    text-align: start;
}

.menu {
    margin: 2rem 3rem;
}

.menu div {
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem;
    margin: 1rem 0;
    align-items: center;
    justify-content: space-between;
    border-left: 5px solid var(--primary);
    color: rgb(49, 49, 49);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.menu .disable {
    border-left: 5px solid lightgrey;
}

.menu span {
    text-transform: uppercase;
    font-size: large;
}

.file-buttons, .obs-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 2.5rem;
}

.file-buttons button {
    margin: 0;
    padding: 10px 35px;
    background-color: #333333;
}

.file-buttons label {
    background-color: #fafbfe;
    color: #484848;
    border: 2px solid #484848;
    padding: 10px 35px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    display: block;
}

.file-buttons:has(> .submitter) {
    margin: .7rem;
}

label:has(+ .submitter) {
    padding: 10px 20px;
}

.submitter + button {
    padding: 10px 20px;
    background-color: #fafbfe;
    color: #484848;
    border: 2px solid #484848;
}

.observation h1 {
    text-transform: uppercase;
    text-decoration: underline;
}

textarea {
    width: 80%;
    border-radius: 10px;
    padding: .5rem;
}

.obs-buttons button:first-child {
    background-color: #fafbfe;
    color: #484848;
    border: 2px solid #484848;
}

.obs-buttons button:last-child {
    background-color: #333333;
    text-transform: uppercase;
}

#camera-container {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    background-color: #000;
    width: 100vw;
    height: 100vh;
    max-width: none;
    max-height: none;
}

.camera-buttons {
    position: fixed;
    z-index: 900;
}

.border {
    display: flex;
    align-items: center;
    margin: 1.5rem auto;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
}

#record {
    border-radius: 100%;
    background-color: red;
    padding: 1.5rem;
    transition: padding .5s, border-radius .5s;
}

.recording {
    padding: 0.5rem !important;
    border-radius: 25% !important;
}

.map {
    height: 20vh;
    max-width: 50vw;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 1rem auto;
}

.map .leaflet-control-container {
    display: block;
}

.avaria-btn {
    display: flex;
    justify-content: space-around;
}

.back-button img {
    margin-top: 0.5rem;
    width: 56px;
    height: 56px;
}

#alert-dialog-description {
    text-align: center;
    font-weight: bold;
}

.err-title {
    color: red;
    text-transform: uppercase;
    display: block;
    margin-bottom: .5rem;
}

@media (orientation: portrait) {
    .camera-buttons{
        bottom: 0;
        width: 100vw;
        height: auto;
    }
}

@media (orientation: landscape) {
    .camera-buttons {
        right: 0;
        top: 40vh;
        width: auto;
        height: 100vh;
    }

    .border {
        margin: auto 1.5rem;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
